import { A } from '@adornis/base/env-info.js';
import type { Maybe, ValueEvent } from '@adornis/base/utilTypes.js';
import { ExtensionBoilerplate } from '@adornis/buildify/client/extensions/ContentExtensionBoilerplate.js';
import { Size } from '@adornis/buildify/client/globals/enums.js';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';
import type { BaseIcon } from '@adornis/buildify/db/extensions/BaseIcon.js';
import { BaseText } from '@adornis/buildify/db/extensions/text/BaseText.js';
import { css } from '@adornis/chemistry/directives/css.js';
import '@adornis/chemistry/elements/components/x-stack.js';
import { html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import type { BuildifyGlobalSettingTagsDefinition } from '../../../../../db/buildify/DigitaleHeldenBuildifyGlobalSettings.js';
import { DubniumBaseIcon } from '../../../../../db/buildify/DubniumBaseIcon.js';
import { DubniumBaseTag, DubniumTagDefinition } from '../../../../../db/buildify/DubniumBaseTag.js';
import '../../../d-accordeon.js';
import './d-buildify-tag-template-picker.js';

export const BuildifyCampusTagExtension = ({
  textExtension,
  iconExtension,
}: {
  textExtension: BuildifyExtension<BaseText>;
  iconExtension: BuildifyExtension<BaseIcon>;
}): BuildifyExtension<DubniumBaseTag> => ({
  class: DubniumBaseTag,
  toolbar: { icon: 'hashtag', text: { default: 'Tags' } },
  render(instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) {
    return html`
      <campus-tag-extension
        .textExtension=${textExtension}
        .iconExtension=${iconExtension}
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
        .hasDropzoneBefore=${dropzoneBefore}
        .hasDropzoneAfter=${dropzoneAfter}
      ></campus-tag-extension>
    `;
  },
  editor({ content, contentController, controllerBaseKeyPath, host }, isGlobalSettingsContexts?: boolean) {
    return html`
      <d-flex space="sm">
        ${isGlobalSettingsContexts
          ? nothing
          : html`
              <d-buildify-tag-template-picker
                placeholder="Template hinzufügen"
                @value-picked=${(e: ValueEvent<BuildifyGlobalSettingTagsDefinition>) => {
                  const tagDefinitions = e.detail.value.tag.items.map(tag => {
                    const copiedTag = new DubniumTagDefinition({ ...tag.toJSON(), _id: A.getGloballyUniqueID() });
                    return copiedTag;
                  });
                  content.items.push(...tagDefinitions);
                  host.requestUpdate();
                }}
              ></d-buildify-tag-template-picker>
            `}
        ${repeat(
          content.items,
          (item, index) => html`
            <d-flex space="xs" crossaxis-center horizontal w100>
              <stack-item flex name=${`${index + 1}. Tag`}>
                <d-flex space="sm">
                  ${item.icon
                    ? html`
                        <d-flex horizontal crossaxis-center space="sm">
                          <stack-item flex name="Icon">
                            ${iconExtension.editor?.({
                              content: item.icon,
                              contentController,
                              controllerBaseKeyPath: [...controllerBaseKeyPath, 'items', index, 'icon'],
                              host,
                            })}
                          </stack-item>
                          <d-icon-button
                            icon="trash"
                            background=${host.colors.tone.warning}
                            @click=${e => {
                              item.icon = null;
                              host.requestUpdate();
                            }}
                          ></d-icon-button>
                        </d-flex>
                      `
                    : html`
                        <d-icon-button
                          icon="plus"
                          text="Icon hinzufügen"
                          @click=${e => {
                            item.icon = new DubniumBaseIcon({});
                            host.requestUpdate();
                          }}
                        ></d-icon-button>
                      `}
                  ${textExtension.editor?.({
                    content: item.text,
                    contentController,
                    controllerBaseKeyPath: [...controllerBaseKeyPath, 'items', index, 'text'],
                    host,
                  })}

                  <d-buildify-color-picker
                    placeholder=${'Hintergrundfarbe'}
                    ${contentController.field(...controllerBaseKeyPath, 'items', index, 'backgroundColor')}
                  ></d-buildify-color-picker>

                  <d-buildify-border-radius-picker
                    select
                    placeholder=${'Abgerundete Ecken'}
                    ${contentController.field(...controllerBaseKeyPath, 'items', index, 'borderRadius')}
                  ></d-buildify-border-radius-picker>

                  <d-buildify-border-picker
                    select
                    placeholder=${'Border'}
                    ${contentController.field(...controllerBaseKeyPath, 'items', index, 'border')}
                  ></d-buildify-border-picker>
                </d-flex>
              </stack-item>

              <d-icon-button
                icon="trash"
                background=${host.colors.tone.warning}
                @click=${e => {
                  if (!content.items) content.items = [];
                  content.items.splice(index, 1);
                  host.requestUpdate();
                }}
              ></d-icon-button>
            </d-flex>
          `,
        )}

        <d-icon-button
          ${css({ alignSelf: 'end' })}
          text="Hinzufügen"
          icon="plus"
          @click=${e => {
            content.items.push(new DubniumTagDefinition({}));
            host.requestUpdate();
          }}
        ></d-icon-button>
      </d-flex>
    `;
  },
  group: 'Basics',
});

@customElement('campus-tag-extension')
export class CampusTagExtension extends ExtensionBoilerplate<DubniumBaseTag> {
  @property({ attribute: false }) textExtension: Maybe<BuildifyExtension<BaseText>>;
  @property({ attribute: false }) iconExtension: Maybe<BuildifyExtension<BaseIcon>>;

  protected override _content(content: DubniumBaseTag) {
    if (!content.items.length) return html` Leeres Tag Element `;

    return html`
      <x-flex
        horizontal
        wrap
        crossaxis-start
        ${css({ gap: this.defaultValue('gap', { global: '.5rem', [Size.MOBILE]: '4px' }) })}
      >
        ${content.items.map((item, index) => this.renderItem(item))}
      </x-flex>
    `;
  }

  protected renderItem(item: DubniumTagDefinition) {
    return html`
      <d-flex
        center
        crossaxis-center
        ${css({
          borderRadius: item.borderRadius ?? this.sizes.borderRadius,
          backgroundColor: item.backgroundColor ?? this.colors.tone.neutralAlabster,
          border: item.border ? item.border : 'none',
          color: this.colors.tone.secondaryText,
          padding: '8px',
          width: 'max-content',
          height: '30px',
        })}
      >
        <d-flex horizontal crossaxis-center space="sm">
          <!-- icon -->
          ${item.icon ? this.iconExtension?.render(item.icon, { isSubelement: true }) : nothing}
          <!-- text -->
          ${this.textExtension?.render(item.text, { defaultValues: { fontSize: '14px' }, isSubelement: true })}
        </d-flex>
      </d-flex>
    `;
  }
}
