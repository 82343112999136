import { logger } from '@adornis/base/logging.js';
import type { Maybe } from '@adornis/base/utilTypes.js';
import { registerQuery } from '@adornis/baseql/metadata/register.js';
import { getRawCollection } from '@adornis/baseql/server/collections.js';
import type { BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { Contact } from '@adornis/digitale-helden-shared/db/Contact.js';
import { getAccessToken } from '@adornis/digitale-helden-shared/db/zoho/GlobalSettings.js';
import { ZOHO_API_URL, requestQueue } from '@adornis/digitale-helden-shared/server/zoho/api.js';
import { DateTime } from 'luxon';
import { LASFile } from '../../../db/files/LASFile.js';

export const getContactProfileImage = registerQuery({
  type: () => LASFile,
  operationName: 'getContactProfileImage',
  resolve: (contactID: string) => {
    return async (gqlFields: BaseQLSelectionSet<LASFile>) => {
      if (!contactID) return;
      const col = await getRawCollection<LASFile>(LASFile._collectionName);
      const existingFileDoc = await col.findOne(
        { 'meta.fileName': { $regex: contactID } },
        { projection: { _id: 1, createdAt: 1 } },
      );
      let existingFile: Maybe<LASFile> = undefined;
      if (existingFileDoc) {
        existingFile = await LASFile.getByID<LASFile>(existingFileDoc._id)(LASFile.allFields);
        if (existingFile && DateTime.fromJSDate(existingFileDoc.createdAt) > DateTime.now().minus({ minutes: 15 })) {
          return existingFile;
        }
      }
      if (!existingFile) {
        existingFile = new LASFile({});
      }

      const url = `${ZOHO_API_URL}/${Contact.ZOHO_MODULE}/${contactID}/photo`;

      let token = await getAccessToken(requestQueue).catch(err => {
        throw new Error(`token couldn't be generated: ${err.message}`);
      });

      try {
        const requestOptions = (token: string) =>
          ({
            method: 'get',
            url,
            data: '',
            options: {
              headers: {
                Authorization: `Zoho-oauthtoken ${token}`,
              },
            },
          } as any);

        const res = await requestQueue.request(requestOptions(token));
        const buf = Buffer.from(res.body);
        await existingFile.replaceByBuffer(buf, 'profile-image-' + contactID + '.png');

        return existingFile as LASFile;
      } catch (e) {
        logger.error({ err: e }, 'Something went wrong fetching the image. Returning last known file.');
        return existingFile;
      }
    };
  },
  params: [{ name: 'contactID', type: () => String }],
});
